<template>
  <div v-loading="loaders.contact">
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Add New Contact</h1>
    </div>
    <contact-form
      button-text="Save"
      @storeContact="storeContact"
    />
  </div>
</template>

<script>
import ContactForm from "./ContactForm";
import BackButton from "@/components/Router/BackButton";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";
export default {
  components :{
    ContactForm,BackButton
  },
  name: "StoreContact",
  mounted() {
    // this.getCategories();
  },
  data() {
    return {
      loaders: {
       contact : false,
      },
      response: {
        categories: [],
        subCategories1: [],
        subCategories2: [],
        checklistItems: [],
      },
    }
  },
  mixins: [
    generateErrorMessageMixin
  ],
  methods: {

    storeContact(event) {
      let vm = this;
      vm.loaders.contact = true;
      var formData = new FormData();
      _.each(event, (value, key) => {
        formData.append(key, value);
      });

      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };

      axios.post(this.$store.getters.getBaseUrl + `/api/content/contacts/store`, formData, {config}).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.$notify.success({
          title: 'Success',
          message: 'Contact created successfully.'
        });
        formData = '';
        vm.$router.back();
      }).catch(error => {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors) : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Contacts',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }).finally(() => {
        vm.loaders.contact = false;
      });
    },
  },

}
</script>

<style scoped>

</style>
