import swal from "sweetalert2";

export default {
  methods: {
    showContentUpdateAlert(cbSuccess = undefined, cbCancel = undefined) {
      swal.fire({
        title: `Success`,
        text: 'Always check to see how your changes look in the Baritastic app.',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary-action'
        },
        showClass: {
          popup: 'animate__animated animate__fadeInDown'
        },
        hideClass: {
          popup: 'animate__animated animate__fadeOutUp'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          if (typeof cbSuccess === 'function') {
            cbSuccess();
          }
        } else {
          if (typeof cbCancel === 'function') {
            cbCancel();
          }
        }
        
      });
    }
  }
}
