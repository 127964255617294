<template>
  <div class="ml-4 mr-4 mt-4">
    <div class="forms">
      <validation-observer v-slot="{handleSubmit}" ref="formValidator">
        <form role="form" type="multipart" @submit.prevent="handleSubmit(onSubmit)">
          <card type="default" header-classes="bg-transparent">
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <div class="form-row">
                  <base-input
                    class="col-6"
                    :rules="{required: true}"
                    name="First Name"
                    label="First Name*" v-model="request.contactForm.firstName"
                    placeholder="First Name">
                  </base-input>
                  <base-input
                    class="col-6"
                    :rules="{required: true}"
                    name="Last Name"
                    label="Last Name*" v-model="request.contactForm.lastName"
                    placeholder="Last Name">
                  </base-input>
                </div>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  type="email"
                  :rules="{required: true}"
                  name="Email"
                  label="Email*" v-model="request.contactForm.email"
                  placeholder="Email">
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  :rules="{required: true}"
                  name="Title"
                  label="Title*" v-model="request.contactForm.title"
                  placeholder="Title">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  :rules="{required: true}"
                  name="Menu Name"
                  label="Menu Name*" v-model="request.contactForm.menuName"
                  placeholder="Menu Name">
                </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <label class="form-control-label">Category</label>
                <el-select
                  class="w-100"
                  v-model="request.contactForm.category"
                  placeholder="Select Category"
                  @change="onChangeCoordinatorCategory"
                >
                  <el-option
                    class="select-danger"
                    :value="0"
                    label="Select Category"
                    key="0">
                  </el-option>
                  <el-option
                    v-for="option in response.categories"
                    class="select-danger"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id">
                  </el-option>
                </el-select>
              </div>
              <div class="col-lg-6 col-md-6">
                <label class="form-control-label">Sub Category 1</label>
                <el-select
                  class="w-100 mb-3"
                  v-model="request.contactForm.subCategory1"
                  placeholder="Select Sub Category 1"
                  @change="onChangeCoordinatorSubCategory"
                >
                  <el-option
                    class="select-danger"
                    :value="0"
                    label="Select Sub Category 1"
                    key="0">
                  </el-option>
                  <el-option
                    v-for="option in response.subCategories1"
                    class="select-danger"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id">
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <label class="form-control-label">Sub Category 2</label>
                <el-select
                  class="w-100 mb-3"
                  v-model="request.contactForm.subCategory2"
                  placeholder="Select Sub Category 2"
                >
                  <el-option
                    class="select-danger"
                    :value="0"
                    label="Select Sub Category 2"
                    key="0">
                  </el-option>
                  <el-option
                    v-for="option in response.subCategories2"
                    class="select-danger"
                    :value="option.id"
                    :label="option.name"
                    :key="option.id">
                  </el-option>
                </el-select>
              </div>
              <div class="col-lg-6 col-md-6 mb-3">
                <div class="d-flex justify-content-end align-items-center">
                  <div class="img-upload"
                       :class="{'w-100':!imageExist}"
                  >
                    <label class="form-control-label" for="profileImage">Profile Image</label>
                    <input type="file" id="profileImage" ref="profileImage" accept="image/png, image/gif, image/jpeg"
                           class="form-control mb-1" @change="onFileChange">
                    <span class="font-size-12px">Recommended image size 500x500 px. Must be less than 500kb.</span>
                  </div>
                  <el-image
                    v-loading="request.loaders.image"
                    class="img-size pl-2"
                    :class="{'d-none':!imageExist}"
                    :src="request.contactForm.imagePreview"
                    @load="handleLoad"
                    @error="handleLoad"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  @input="acceptNumber"
                  :rules="{required: true}"
                  name="Phone"
                  type="tel"
                  placeholder="123-456-7890"
                  label="Phone*" v-model="request.contactForm.phone"
                >
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  name="Address"
                  label="Address" v-model="request.contactForm.address"
                  placeholder="Address">
                </base-input>

              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  name="Zip Code"
                  label="Zip Code"
                  :maxlength="12"
                  :value="request.contactForm.zipCode"
                  @input="handleZipInput"
                  placeholder="Zip Code">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                  <base-input
                    class=""
                    name="City"
                    label="City" v-model="request.contactForm.city"
                    placeholder="Austin, TX">
                  </base-input>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-6 col-md-6">
                <base-input
                  class=""
                  name="Hospital Name"
                  label="Practice/Hospital Name" v-model="request.contactForm.hospitalName"
                  placeholder="Hospital Name">
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
                <base-input label="Status">
                  <el-select
                    class="w-100"
                    label="Status"
                    v-model="request.contactForm.status">
                    <el-option
                      v-for="option in dropdowns.status"
                      class="select-danger"
                      :value="option.value"
                      :label="option.label"
                      :key="option.label">
                    </el-option>
                  </el-select>
                </base-input>
              </div>
            </div>
            <div class="row">
              <div v-if="authUser.program_id == henryFordId" class="col-lg-6 col-md-6">
                <base-input label="Weight Loss Type *"
                            name="WeightLossType"
                 :rules="{required: true}"
                >
                  <el-select v-model="request.contactForm.weightLossType" :default-first-option="true">
                    <el-option value="1" label="Surgical"></el-option>
                    <el-option value="2" label="Non-Surgical"></el-option>
                  </el-select>
                </base-input>
              </div>
              <div class="col-lg-6 col-md-6">
              </div>
            </div>
            <div class="row">
              <div class="col equal-height-columns">
                <label  class="form-control-label">About*</label>
                <editor
                  v-model="request.contactForm.about"
                  :api-key=editorApiKey
                  :init=editorOption
                />
              </div>
            </div>
          </card>
        </form>
        <base-button
          @click="onSubmit('contactForm')"
          class="btn-primary-action mb-4 br-4 pr-5 pl-5"
          native-type="submit"
          type="submit">
          {{ buttonText }}
        </base-button>
      </validation-observer>
    </div>
  </div>
</template>

<script>

import _ from "lodash";
import Helper from '../../../../util/globalHelpers'
import  { store } from "@/vuex/store";

export default {
  name: "ContactForm",
  props: ['categories', 'subCategories1', 'subCategories2', 'contactItemToEdit', 'buttonText'],
  data() {

    return {
      editorApiKey: process.env.VUE_APP_EDITOR_API_KEY,
      editorOption: store.state.toolbarOptions,
      henryFordId: process.env.VUE_APP_HENRY_FORD_ID,
      authUser: {},
      modals: {
        detailsModal: false,
        checklistItemsModal: false,
      },
      request: {
        loaders:{
          image: false,
        },
        contactForm: {
          firstName: '',
          lastName: '',
          email: '',
          title: '',
          menuName: '',
          category: 0,
          subCategory1: 0,
          subCategory2: 0,
          image: '',
          imagePreview: '',
          phone: '',
          address: '',
          zipCode: '',
          city: '',
          hospitalName: '',
          status: 1,
          weightLossType: '',
          about: '',
        },
      },
      response: {
        categories: [],
        subCategories1: [],
        subCategories2: [],
      },
      dropdowns: {
        status: [{
          value: 1,
          label: 'Active'
        },
          {
            value: 0,
            label: 'Inactive',
          },
        ],
      },
    }
  },
  mounted() {
    this.fetchCoordinatorCategories();
  },
  watch: {
    contactItemToEdit(newVal) {
      this.request.loaders.image = true
      // console.log('Watcher',newVal);
      this.request.contactForm.firstName = newVal.first_name ?? '';
      this.request.contactForm.lastName = newVal.last_name ?? '';
      this.request.contactForm.email = newVal.email ?? '';
      this.request.contactForm.title = newVal.title ?? '';
      this.request.contactForm.menuName = newVal.menu_name ?? '';
      this.request.contactForm.phone = newVal.phone ?? '';
      this.request.contactForm.address = newVal.address ?? '';
      this.request.contactForm.zipCode = newVal.pin_code ?? '';
      this.request.contactForm.city = newVal.city ?? '';
      this.request.contactForm.category = newVal.coordinator_category_id ?? '';
      this.request.contactForm.subCategory1 = newVal.coordinator_sub_category_id;
      this.request.contactForm.subCategory2 = newVal.coordinator_last_category_id;
      this.request.contactForm.hospitalName = newVal.practice ?? '';
      this.request.contactForm.about = newVal.about ?? '';
      this.request.contactForm.status = newVal.status;
      this.request.contactForm.weightLossType = newVal.weight_loss_type;
      if (newVal.hasOwnProperty('coordinator_category')) {
        this.fetchCoordinatorSubCategories(newVal.coordinator_category_id);
      }
      if (newVal.hasOwnProperty('coordinator_sub_category')) {
        this.fetchCoordinatorLastCategories(newVal.coordinator_sub_category_id);
      }
      this.request.contactForm.imagePreview = newVal.profile_image
    }
  },
  methods: {
    /**
     * Accept and format number in 999-999-9999
     */
    acceptNumber() {
      this.request.contactForm.phone = Helper.formatPhoneNumber(this.request.contactForm.phone)
    },

    onEditorBlur(quill) {
      console.log('editor blur!', quill)
    },
    onEditorFocus(quill) {
      console.log('editor focus!', quill)
    },
    onEditorReady(quill) {
      console.log('editor ready!', quill)
    },
    onEditorChange({ quill, html, text }) {
      console.log('editor change!', quill, html, text)
      this.content = html
    },

    onFileChange(e){
      this.request.contactForm.image = e.target.files[0];
    },

    handleZipInput(e) {
      if (Helper.isAlphaNumeric(e)) this.request.contactForm.zipCode = e
      else if(e==='')this.request.contactForm.zipCode =''
    },

    handleKeyPressForEditor(value) {
      this.request.contactForm.about = this.$refs.editor.getContent();
    },

    onChangeCoordinatorCategory(coordinatorCategory){
      if (coordinatorCategory == 0){
        this.response.subCategories1 = [];
        this.request.contactForm.subCategory1 = 0;
        this.response.subCategories2 = [];
        this.request.contactForm.subCategory2 = 0;
      }
      else {
        this.fetchCoordinatorSubCategories(coordinatorCategory);
      }
    },

    onChangeCoordinatorSubCategory(coordinatorSubCategory){
      if (coordinatorSubCategory == 0){
        this.response.subCategories2 = [];
        this.request.contactForm.subCategory2 = 0;
      }
      else {
        this.fetchCoordinatorLastCategories(coordinatorSubCategory);
      }
    },

    fetchCoordinatorCategories() {
      let vm = this;

      //fetching auth user through api .. will implement better approach later
      this.$store.dispatch('_getSubUser').then(response => {
        vm.authUser = response.data;
      }).catch(err => {

      });

      axios.get(this.$store.getters.getBaseUrl + '/api/content/coordinator-categories/all')
        .then((response) => {
          vm.$store.commit('toggleSessionCheck', true)
          vm.$store.commit('updateSessionTime', 0)
          vm.response.categories= response.data.data;
        })
        .catch((error) => console.error(error.response));
    },

    fetchCoordinatorSubCategories(id) {
      let vm = this;
      if (id){
        axios.get(this.$store.getters.getBaseUrl + '/api/content/coordinator-sub-categories/by-category-id?categoryId=' + id)
          .then((response) => {
            vm.$store.commit('toggleSessionCheck', true)
            vm.$store.commit('updateSessionTime', 0)
            vm.response.subCategories1 = response.data.data;
          })
          .catch((error) => console.error(error.response));
      }
    },

    fetchCoordinatorLastCategories(id) {
      let vm = this;
      if (id){
        axios.get(this.$store.getters.getBaseUrl + '/api/content/coordinator-last-categories/by-sub-category-id?subCategoryId=' + id)
          .then((response) => {
            vm.$store.commit('toggleSessionCheck', true)
            vm.$store.commit('updateSessionTime', 0)
            vm.response.subCategories2 = response.data.data;
          })
          .catch((error) => console.error(error.response));
      }
    },

    async onSubmit(formName) {
      const isContactFormValid = await this.$refs['formValidator'].validate();

      if (isContactFormValid) {
        this.request.contactForm.about = Helper.addCustomStylingToContent(this.request.contactForm.about)
        if (this.$route.name === 'StoreContact') {
          this.$emit('storeContact', this.request.contactForm);
        } else if (this.$route.name === 'EditContact') {
          this.$emit('updateContact', this.request.contactForm);
        } else {
        }
      }
      else {
        this.$notify.error({
          title: 'Validation Error',
          message: 'Please enter values in the form'
        });
      }
    },
    handleLoad() {
      this.request.loaders.image = false
    }
  },
  computed: {
    imageExist() {
      return this.request.contactForm.imagePreview !== ''
        && this.request.contactForm.imagePreview
        && this.request.contactForm.image === ''
    }
  },
}
</script>

<style scoped>

.img-size{
  width: 120px;
  height: 100px;
  display: inline-block;
}

.img-upload{
  width: calc(100% - 120px);
  display: inline-block;
}

</style>
