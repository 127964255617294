<template>
  <div v-loading="loaders.forms">
    <!-- Top Navigation -->
    <div class="ml-4">
      <back-button/>
    </div>
    <!-- Top Navigation Finished-->
    <div class="checklist-template-header pl-4 pr-4 pt-4">
      <h1 class="">Edit Contact</h1>
    </div>
    <contact-form
      button-text="Update"
      :contact-item-to-edit="response.contact"
      @updateContact="updateContact"
    />
  </div>
</template>

<script>
import BackButton from "@/components/Router/BackButton";
import ContactForm from "./ContactForm";
import generateErrorMessageMixin from "@/mixins/generateErrorMessageMixin";
import swalFireMixin from "../../../../mixins/swalFireMixin";

export default {
  name: "EditContact",
  components: {BackButton,ContactForm},
  data() {
    return {
      request: {
        id: this.$route.params.id,
      },
      response: {
        contact: {},
        categories: [],
      },
      loaders: {
        forms: false,
        categories: false
      },
    }
  },
  mixins: [
    generateErrorMessageMixin,
    swalFireMixin
  ],
  methods: {

    getContact() {
      let vm = this;
      vm.loaders.forms = true
      axios.get(this.$store.getters.getBaseUrl + '/api/content/contacts/' + this.request.id).then((response) => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.response.contact = response.data.data;
      }).catch(error => {
        let message = error.response ? error.response.data.message : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Error',
          message: message
        });
      }).finally(() => {
        vm.loaders.forms = false
      });
    },

    fetchCategories() {
      this.loaders.categories = true
      this.loaders.forms = true
      axios.get(`${this.$store.getters.getBaseUrl}/api/user-category/1`)
        .then((response) => {
          this.$store.commit('toggleSessionCheck', true)
          this.$store.commit('updateSessionTime', 0)
          this.response.categories = response.data.data
          this.response.categories.empty = false
        })
        .catch((error) => console.error(error.response.message))
        .finally(() => {
          this.loaders.categories = false
          this.loaders.forms = false
        })
    },

    updateContact(event) {
      let vm = this;
      vm.loaders.forms = true;
      var formData = new FormData();
      formData.append('id', this.request.id);
      _.each(event, (value, key) => {
        formData.append(key, value);
      });
      const config = {
        headers: { 'content-type': 'multipart/form-data' }
      };

      axios.post(this.$store.getters.getBaseUrl + `/api/content/contacts/update`, formData, {config}).then(response => {
        vm.$store.commit('toggleSessionCheck', true)
        vm.$store.commit('updateSessionTime', 0)
        vm.showContentUpdateAlert();
        vm.$router.back();
      }).catch(error => {
        let message = error.response ? vm.generateErrorMessage(error.response.data.errors) : 'Something went wrong please try again in few minutes.';
        vm.$notify.error({
          title: 'Error',
          dangerouslyUseHTMLString: true,
          message: message
        });
      }).finally(() => {
        vm.loaders.forms = false;
      });
    }
  },
  mounted() {
    this.getContact();
  }
}
</script>

<style scoped>

</style>

<style>

.edit-patient .edit-patient-card-headings {
  text-align: left;
  font: normal normal normal 17px/23px Open Sans;
  letter-spacing: 0;
  color: #8898AA;
  text-transform: uppercase;
  opacity: 1;
}

.patient-detail-form-container .el-form--label-top .el-form-item__label {
  padding: 0 !important;
}

.patient-detail-form-container .el-form-item__label {
  line-height: 20px;
  font-weight: 500 !important;
  color: black;
}

.patient-detail-form-container .el-form * {
  font: normal normal normal 14px/19px Open Sans;
}

.patient-detail-form-container .el-input.is-active .el-input__inner,
.patient-detail-form-container .el-input__inner:focus,
.patient-detail-form-container .el-select .el-input.is-focus .el-input__inner,
.patient-detail-form-container .el-select .el-input .el-input__inner:focus {
  border-color: #BDC4CC !important;
  box-shadow: 0 3px 2px #E9ECEF0D;
}

.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:before {
  display: none;
}

.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) .el-form-item__label-wrap > .el-form-item__label:before,
.patient-detail-form-container .el-form-item.is-required:not(.is-no-asterisk) > .el-form-item__label:after {
  content: '*';
  margin-left: 4px;
}

</style>

